import React from "react"
import Layout from "../components/Layout";
import '../styles/global.css';

const resourceLinkInList = (link, label) => (<li>
        <a href={link} target="_blank" rel="noopener noreferrer"  className="linkInParagraph" style={{'fontSize':'1.35em'}}>
            {label}</a>
</li>);

export default function ResourceLinks() {
  return (
    <Layout>
      <h2 align='center'>Resource Links</h2>
      <br />
      <div className='resourceLinkClass'>
      <h3>Partnerships</h3>
      <ul>
          { resourceLinkInList('https://www.taubmaninstitute.org/', 'Taubman Institute') }
          { resourceLinkInList('https://precisionhealth.umich.edu/our-research/michigangenomics/', 'Michigan Genomics Institute') }
          { resourceLinkInList('https://www.copdfoundation.org/', 'COPD Foundation') }
      </ul>
      <br />
      <h3>Education and Support</h3>
      <ul>
          { resourceLinkInList('https://www.uofmhealth.org/health-library/qtsmk', 'UM Smoking Cessation group') }
          { resourceLinkInList('https://www.copdfoundation.org/What-is-COPD/Understanding-COPD/What-is-COPD.aspx', 'COPD Foundation – what is COPD') }
          { resourceLinkInList('https://www.lung.org/lung-health-diseases/lung-disease-lookup/copd', 'American Lung Association') }
      </ul>
      <br />
      <h3>Miscellaneous</h3>
      <ul>
        { resourceLinkInList('https://www.uofmhealth.org/conditions-treatments/pulmonary/copd-clinic', 'University of Michigan COPD/Pulmonary Clinic')}
        { resourceLinkInList('https://clinicaltrials.gov/', 'Clinicaltrial.gov (Search: COPD or Study ID: NCT04968249)')}
      </ul>
      </div>
    </Layout>
  )
}
